import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UploadRequest } from '../_models/upload-request';
import { McrbApi } from 'src/app/_models';
import { PaginatedResponse } from '../_models/paginated-response.model';
import { ToastService } from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  uploadReq: UploadRequest;
  mcrbApi: McrbApi;
  baseUrl = environment.baseUrl;

  

  constructor(private http: HttpClient,private toastService: ToastService) {}

  /**
   * @description
   * Sends Http request for uploaded batches 
   * for a particular partner
   * 
   * @param partnerId the partner id 
   * @param pageNo the page number 
   * @param pageSize the size/elements per page
   * @param requestCode request code of the batch
   * @returns Observable<McrbApi>
   */
  public getUploadBatch(userId: string, partnerId?: string, pageNo?: number, pageSize?: number, requestCode?: string): Observable<McrbApi> {
    //set up request parameters
    let params: HttpParams = this.constructHttpParams(userId, partnerId, pageNo, pageSize, requestCode);
    return this.http.get<McrbApi>(this.baseUrl + 'datasubmission/upload/requests', { params });
  }

  /**
   * @description
   * Constructs a HttpParam according to parameters provided
   * and the roles of the user making the request
   * 
   * @param userId 
   * @param partnerId 
   * @param pageNo 
   * @param pageSize 
   * @param requestCode 
   * @returns HttpParams 
   */
  private constructHttpParams(userId: string, partnerId: string, pageNo?: number, pageSize?: number, requestCode?: string): HttpParams{
    if (userId != null || userId != undefined)
      return this.getUnAuthorisedHttpParam(userId, pageNo, pageSize, requestCode)
    if (requestCode != undefined || requestCode != null)
      return new HttpParams()
        .set('partner_id', partnerId)
        .set('page', pageNo.toString())
        .set('page_size', pageSize.toString())
        .set('request_code', requestCode);
    return new HttpParams()
      .set('partner_id', partnerId.toString())
      .set('page', pageNo.toString())
      .set('page_size', pageSize.toString());
  }

  /**
   * @description
   * Constructs HttpParam 
   * for requests made by users 
   * without the role id 1 and 3
   * it checks if request includes a request_code/batchNo
   * and returns the approprite HttpParam
   * 
   * @param userId 
   * @param pageNo 
   * @param pageSize 
   * @param requestCode 
   */
  private getUnAuthorisedHttpParam(userId: string, pageNo?: number, pageSize?: number, requestCode?: string) {
    if (requestCode != null || requestCode != undefined)
      return new HttpParams()
        .set('user_id', userId)
        .set('page', pageNo.toString())
        .set('page_size', pageSize.toString())
        .set('request_code', requestCode);
    return new HttpParams()
      .set('user_id', userId)
      .set('page', pageNo.toString())
      .set('page_size', pageSize.toString());
  }

  /**
   * Retrieves the files for the batch 
   * from the api
   * 
   * @param batchNo 
   */
  public getBatchFilesUpdates(batchNo: string): Observable<McrbApi>{
    let params: HttpParams = new HttpParams().set('request_code', batchNo);
    return this.http.get<McrbApi>(this.baseUrl + 'datasubmission/upload/file', { params });
  }

  getUploadRequest(userId: String, page: number) {
    const href = this.baseUrl + 'datasubmission/upload/requests';
    const requestUrl = `${href}?user_id=${userId}&page=${page + 1}`;
    //return this.http.get<McrbApi>(requestUrl, { observe: 'response' });
    return this.http.get<McrbApi>(requestUrl);
  }

  /**
   * @deprecated use { getBatchFilesUpdates } function
   * @param requestCode 
   */
  getUploadRequestFiles(requestCode: String) {
    //return this.http.get<UploadRequestFileRes>('http://localhost:5559/api/v1/datasubmission/upload/file?request_code='+requestCode+'&page=1').pipe(
    return this.http.get<McrbApi>(this.baseUrl + 'datasubmission/upload/file?request_code=' + requestCode).pipe(
      map(uploadReq => {
        return uploadReq;
      })
    );
  }

  /**
   * Requests for the processing of batch files' logs
   * from the api
   * 
   * @param referenceCode 
   */
  public generateBatchFileLogs(referenceCode: string): Observable<McrbApi>{
    let params: HttpParams = new HttpParams().set('reference_code', referenceCode);
    return this.http.post<McrbApi>(this.baseUrl + 'datasubmission/error/generatelog', null, { params });
  }

  /**
   * @deprecated
   * @param referenceCode 
   */
  generateLogsRequest(referenceCode: String) {
    return this.http.post<any>(this.baseUrl + 'datasubmission/error/generatelog?reference_code=' + referenceCode, null).pipe(
      map(uploadReq => {
        return uploadReq;
      })
    );
  }

  getErrorLogs(url: string) {
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadFile(url: string) {
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  public getListOfTemplates(timePeriod:string,pageSize: string,isDeleted:boolean): Observable<PaginatedResponse> {
    let parameters = new HttpParams()
        .set('page_size', pageSize);

    return this.http.get<PaginatedResponse>(this.baseUrl + `datasubmission/submit/template?time_period=${timePeriod}&deleted=${isDeleted}`, { params: parameters }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500)
          return throwError(error)
        if (error.status == 401)
          return throwError(error)
        if (error.status == 0)
          return throwError(error)

      //  this._toastr.error(error.error.api_code_description);
        return throwError(error)
      })
    );
  }

  public getListOfTemplatesPerTemplateVersion(timePeriod:string,isDeleted:boolean,version:string,pageSize: string): Observable<PaginatedResponse> {
    let parameters = new HttpParams()
        .set('page_size', pageSize);

    return this.http.get<PaginatedResponse>(this.baseUrl + `datasubmission/submit/template?time_period=${timePeriod}&deleted=${isDeleted}&version=${version}`, { params: parameters }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500)
          return throwError(error)
        if (error.status == 401)
          return throwError(error)
        if (error.status == 0)
          return throwError(error)

      //  this._toastr.error(error.error.api_code_description);
        return throwError(error)
      })
    );
  }

  


  public getListOfPerSector(timePeriod:string,pageSize: string,isDeleted:boolean,sector:number): Observable<PaginatedResponse> {
    let parameters = new HttpParams()
        .set('page_size', pageSize);

    return this.http.get<PaginatedResponse>(this.baseUrl + `datasubmission/submit/template?time_period=${timePeriod}&deleted=${isDeleted}&sector=${sector}`, { params: parameters }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500)
          return throwError(error)
        if (error.status == 401)
          return throwError(error)
        if (error.status == 0)
          return throwError(error)

      //  this._toastr.error(error.error.api_code_description);
        return throwError(error)
      })
    );
  }

  updateTemplateInfo(template_version: string,template_name: string,initials: string,sector: string, file_naming_format: string, reference_code: string): Observable<Response> {
    const templateData = {
      template_version: template_version,
      template_name: template_name,
      initials: initials,
      sector: sector,
      file_naming_format: file_naming_format,
      reference_code: reference_code,
    };
  
    return this.http.patch<Response>(this.baseUrl + 'datasubmission/submit/template', templateData).pipe(
      catchError((error: HttpErrorResponse) => {
        const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-top-center'};
        if (error.status == 500)
          return throwError(error);
        if (error.status == 401)
          return throwError(error);
        if (error.status == 0)
          return throwError(error);
        if (error.status == 409) {
          if (error.error && error.error.has_error && error.error.has_error === true && error.error.api_code_description) {
           // this._toastr.error(error.error.api_code_description);
            return throwError(error.error.api_code_description); // Return the error message
          } else {
         //   this._toastr.error('Conflict error occurred', 'Error');
            return throwError('Conflict error occurred'); // Return a default message if api_code_description is not present
          }
        }
  
        // Handle other error cases
        // this._toastr.error('An error occurred', 'Error');
        // return throwError('An error occurred');
      })
    );
  }




  public deleteTemplate(reference_code:string): Observable<PaginatedResponse> {
    
    let parameters = new HttpParams()
        // .set('page_size', pageSize);

    return this.http.delete<PaginatedResponse>(this.baseUrl + `datasubmission/submit/template?reference_code=${reference_code}`, { params: parameters }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500)
          return throwError(error)
        if (error.status == 401)
          return throwError(error)
        if (error.status == 0)
          return throwError(error)

      //  this._toastr.error(error.error.api_code_description);
        return throwError(error)
      })
    );
  }

 
  
  
  downloadFiles(reference_code: string, filename: string): void {
    this.http.get(this.baseUrl + `datasubmission/submit/template/download?reference_code=${reference_code}`, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename; // Set the filename here
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
  
  uploadTemplate(
  
    file: File,
    templateName: string,
    sector: string,
    templateVersion: string,
    initials: string,
    fileNamingFormat: string,
    timePeriod: string
  ): Observable<Response> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('templateName', templateName);
    formData.append('sector', sector);
    formData.append('templateVersion', templateVersion);
    formData.append('initials', initials);
    formData.append('fileNamingFormat', fileNamingFormat);
    formData.append('timePeriod', timePeriod);
  
    return this.http.post<Response>(this.baseUrl + 'datasubmission/submit/template', formData).pipe(
      catchError((error: HttpErrorResponse) => {
        const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-top-center'};
        if (error.status === 500 || error.status === 401) {
          return throwError(error);
        }
  
        if (error.status === 400) {
          // if (error.error && error.error.has_error && error.error.has_error === true && error.error.api_code_description) {
          //   return throwError(error.error.api_code_description); // Return the error message
          // } else {
          //   return throwError('Conflict error occurred'); // Return a default message if api_code_description is not present
          // }
        }
  
        if (error.status === 409) {
          // Handle 409 conflict error
        //  this.toastService.error('Authentication Failure', 'Invalid username or password!', options);
        }
  
        // If none of the above conditions met, propagate the error
        return throwError(error);
      })
    );
  }
  
}
