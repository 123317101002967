import { Injectable } from '@angular/core';
import { CurrentUser } from '../../_models';
import { AuthUserDetails } from '../_models';
import { AuthenticationService } from './authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class AuthUserDetailsService {

  private token;
  private userDetails:AuthUserDetails;
  private user:CurrentUser;

  constructor(private authService: AuthenticationService, private helper: JwtHelperService) { 
    this.token = authService.currentUserValue;
  }

  getToken():any{
    return this.helper.decodeToken(this.token.access_token).user
  }

  getUserDetails():AuthUserDetails{
    //console.log(<AuthUserDetails>(this.getToken()));
    return <AuthUserDetails>(this.getToken());
  }

  getPartnerName():string{
    return this.getUserDetails().partner.name;
  }

  getPartnerId():number{
    return this.getUserDetails().partner.id;
  }

  getBranchName():string{
    return this.getUserDetails().partner_branch.name;
  }
  getInstitutionCode():string{
    return this.getUserDetails().partner.code;
  }

  getCugId():number{
    return this.getUserDetails().partner.cug_id;
  }

  getUserId() :string{
    return this.getUserDetails().id;
  }

  getUserNames(): string{
    return this.getUserDetails().first_name + " " + this.getUserDetails().last_name;
  }

  getFirstName(): string{
    return this.getUserDetails().first_name;
  }

  getRolesList(): Array<Role>{
    return this.getUserDetails().roles;
  }

  getClosedUserGroup():number {
    return this.getUserDetails().closed_user_group;    
  }

}
