import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/_guards/auth-guard';
import { UsersComponent } from './usermanager/users/users.component';
import { UserUploadComponent } from './upload/user-upload/user-upload.component';
import { NewUploadComponent } from './upload/new-upload/new-upload.component';
import { ResetRequestComponent } from './auth/reset-request/reset-request.component';
import { ResetRequestSuccessComponent } from './auth/reset-request-success/reset-request-success.component';
import { ResetRequestActivateComponent } from './auth/reset-request-activate/reset-request-activate.component';
import { UserDetailsComponent } from './usermanager/user-details/user-details.component';
import { NewClientComponent } from './usermanager/new-client/new-client.component';
import { MonthlyTemplateComponent } from './monthly-template/monthly-template.component';
import { DailyTemplateComponent } from './daily-template/daily-template.component';
import { UploadTemplateComponent } from './upload-template/upload-template.component';
import { OtherTemplateComponent } from './other-template/other-template.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent,
  canActivate: [AuthGuard], children:[
      {path:'new_upload', component: NewUploadComponent},
      {path:'my_upload', component: UserUploadComponent},
      {path:'users', component: UsersComponent},
      {path:'user/:id', component: UserDetailsComponent},
      {path:'new_client', component: NewClientComponent},
      {path:'monthly', component: MonthlyTemplateComponent},
      {path:'daily', component: DailyTemplateComponent},
      {path:'others', component: OtherTemplateComponent},
      {path:'upload_template', component: UploadTemplateComponent},
  ]},
  { path: 'landing', component: LandingComponent },
  { path: 'reset_request', component: ResetRequestComponent },
  { path: 'reset_request_activate', component: ResetRequestActivateComponent },
  { path: 'reset_request_success', component: ResetRequestSuccessComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
