// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   //baseUrl: "http://localhost:5559/api/v1/"
 //baseUrl:"http://data-validation.metropol.co.ke/"
 //baseUrl:"https://dvt.metropol.co.ke/"
 baseUrl: "/"
};



