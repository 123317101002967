import { BrowserModule } from '@angular/platform-browser';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AccordionModule, DatepickerModule, InputsModule, MDBBootstrapModulesPro, MDBSpinningPreloader, NavbarModule, SidenavModule, WavesModule, TableModule, ToastModule, StepperModule, ModalModule } from 'ng-uikit-pro-standard';
import {MatSortModule, MatTableModule, MatPaginatorModule, MatButtonModule, MatSelectModule, MatBadgeModule, MatChipsModule, MatTooltipModule} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import { NgxUploaderModule } from 'ngx-uploader';
import {FileSizeModule} from 'ngx-filesize';
import { FileSaverModule } from 'ngx-filesaver';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JwtModule } from '@auth0/angular-jwt';
import { UsersComponent } from './usermanager/users/users.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { NewUserComponent } from './usermanager/new-user/new-user.component';
import { UserUploadComponent } from './upload/user-upload/user-upload.component';
import { NewUploadComponent } from './upload/new-upload/new-upload.component';
import { ResetRequestComponent } from './auth/reset-request/reset-request.component';
import { ResetRequestSuccessComponent } from './auth/reset-request-success/reset-request-success.component';
import { ResetRequestActivateComponent } from './auth/reset-request-activate/reset-request-activate.component';
import { UserDetailsComponent } from './usermanager/user-details/user-details.component';
import { EditUserComponent } from './usermanager/edit-user/edit-user.component';
import { SessionTimeoutComponent } from './auth/session-timeout/session-timeout.component';
import { CounterDirectiveDirective } from './_directives/counter-directive.directive';
import { NewClientComponent } from './usermanager/new-client/new-client.component';
import { CommonModule } from '@angular/common';
import { SwitchCasesDirective } from './_directives/switch-cases.directive';
import { MonthlyTemplateComponent } from './monthly-template/monthly-template.component';
import { DailyTemplateComponent } from './daily-template/daily-template.component';
import { UploadTemplateComponent } from './upload-template/upload-template.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OtherTemplateComponent } from './other-template/other-template.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LoginComponent,
    DashboardComponent,
    UsersComponent,
    NewUserComponent,
    UserUploadComponent,
    NewUploadComponent,
    ResetRequestComponent,
    ResetRequestSuccessComponent,
    ResetRequestActivateComponent,
    UserDetailsComponent,
    EditUserComponent,
    SessionTimeoutComponent,
    CounterDirectiveDirective,
    NewClientComponent,
    SwitchCasesDirective,
    MonthlyTemplateComponent,
    DailyTemplateComponent,
    UploadTemplateComponent,
    OtherTemplateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    SweetAlert2Module.forRoot(),
    AccordionModule,
    DatepickerModule,
    InputsModule,
    NavbarModule,
    SidenavModule,
    WavesModule,
    TableModule,
    StepperModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSelectModule,
    MatBadgeModule,
    MatChipsModule,
    MatTooltipModule,
    ModalModule,
    CdkTableModule,
    NgxUploaderModule,
    FileSizeModule,
    FileSaverModule,
    JwtModule.forRoot({
      config: {
        // ...
        tokenGetter: jwtTokenGetter
      }}),
      UserIdleModule.forRoot({idle: 900, timeout: 30, ping: 120})
      //UserIdleModule.forRoot({idle: 15, timeout: 30, ping: 30})
  ],
  entryComponents: [ NewUserComponent, EditUserComponent, SessionTimeoutComponent],
  providers: [
    MDBSpinningPreloader,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}
