import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  modalRef: MDBModalRef;
  loginForm: FormGroup;
  successMessage = 'Custom success message';
  errorMessage = 'Custom error message';
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    //private alertService: AlertService,
    private modalService: MDBModalService,
    private toastService: ToastService) { 
        this.loginForm = formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }

    ngOnInit(){
        // reset login status
        //this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    /*onSubmit() {

        console.log("Is form valid? "+this.loginForm.valid);
        if (this.loginForm.valid) {
          this.authService.login(this.loginForm.value);
        }
        //this.formSubmitAttempt = true;
    }*/

    // convenience getter for easy access to form fields
    
    get f() { return this.loginForm.controls; }
    get username() { return this.loginForm.get('username'); }
    get password() { return this.loginForm.get('password'); }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.loading = false;
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.loading = false;
                    console.error(error);
                    const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-top-center'};
                    if(error.status == 0){
                        this.toastService.error('Network Failure', 'Unable to connect to resource!', options);
                    }

                    else if(error.status == 401){
                        this.toastService.error('Authentication Failure', 'Invalid username or password!', options);
                    } else {
                        this.toastService.error('Unknown Error', 'Please contact Admin!', options);
                    }
                    
                });
    }

}
