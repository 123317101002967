import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Subject, timer, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
  action: Subject<any> = new Subject();
  timeLeft: number = 0;
  counter = 30;
  interval = 1000;
  @Input() value: any;
  first_name: any;

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit() {
  }

  closeSessionTimeoutModal(event: String){
    this.action.next(event);
    //this.modalRef.hide();
  }

  displayCounter(count) {
    //console.log(count);
    this.timeLeft = count;
    if(count == 0){
      this.closeSessionTimeoutModal("close");
    }
  }
  

}
