import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerBranch, SessionTime } from 'src/app/auth/_models';
import { UserService } from '../_services';
import { first } from 'rxjs/operators';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit {

  createClientForm: FormGroup;
  pattern: RegExp = /^[a-zA-Z0-9-\']+$/;
  partnerOption: any;
  partnerSelect: Array<any>;
  partnerList: Array<PartnerBranch>;
  sessionTimeOption: any;
  sessionTimeSelect: Array<any>;
  sessionTimeList: Array<SessionTime>;
  newApp: any;
  publicKey: string = '';
  privateKey: string = '';
  applicationName = '';
  successTokenGen: boolean = false;
  institutionCode: string;

  

  constructor(private fb: FormBuilder, private userService: UserService, private toastService: ToastService) { 
    this.partnerSelect = [];
    this.sessionTimeSelect = [];
    this.createClientForm = fb.group({
      appName: ['', [Validators.required, Validators.maxLength(15), Validators.pattern(this.pattern)]],
      selectPartner: [null, Validators.required],
      selectSessionTime: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getPartner();
    this.getSessionTime();   
  }

  get appName() { return this.createClientForm.get('appName'); }
  get selectPartner() { return this.createClientForm.get('selectPartner'); }
  get selectSessionTime() { return this.createClientForm.get('selectSessionTime'); }

  getPartner(){
      this.userService.getPartnerDetails().pipe(first())
      .subscribe(
        data => {
          this.partnerList = data.data as PartnerBranch[];
          if(this.partnerList.length > 0){
            this.partnerList.forEach(partner =>{
              this.partnerOption = {
                value: partner.id,
                label: partner.name,
                code: partner.code
              }
              this.partnerSelect.push(this.partnerOption);
            })
          }

          this.partnerSelect = this.partnerSelect.slice();
          console.log(this.partnerSelect);
        },
        error => {
            console.error(error);
        })
  }

  getSessionTime(){
    this.userService.getSessionTime().pipe(first())
    .subscribe(
      data => {
        this.sessionTimeList = data.data as SessionTime[];
        if(this.sessionTimeList.length > 0){
          this.sessionTimeList.forEach(sessionTime =>{
            this.sessionTimeOption = {
              value: sessionTime.seconds,
              label: sessionTime.name
            }
            this.sessionTimeSelect.push(this.sessionTimeOption);
          })
        }

        this.sessionTimeSelect = this.sessionTimeSelect.slice();
      },
      error => {
          console.error(error);
      })
}

  onSubmit() {
    if (this.createClientForm.invalid) {
      return;
    }
    console.log(this.createClientForm);
    this.institutionCode = this.partnerSelect.find(s => this.createClientForm.value.selectPartner == s.value).code;
    console.log(this.institutionCode);
    this.newApp = {
      app_name: this.createClientForm.value.appName+'-'+this.institutionCode,
      grant_type: "client_credentials",
      institution_code: this.institutionCode,
      partner_id: this.createClientForm.value.selectPartner,
      access_token_validity: this.createClientForm.value.selectSessionTime
    }

    this.userService.createApp(this.newApp).pipe(first())
    .subscribe(data => {
        console.log(data);
        this.successTokenGen = true;
        this.applicationName = data.data.app;
        this.publicKey = data.data.public_key;
        this.privateKey = data.data.private_key;
    },
    error => {
      console.log(error);
      this.successTokenGen = false;
      const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-bottom-right'};
      if(error.status == 0){
        this.toastService.error('Network Failure', 'Unable to connect to resource!', options);
      }

      else if(error.status == 401){
        this.toastService.error('Session Failure', 'Invalid Session!', options);
     } else if(error.status == 409){
      this.toastService.error(error.error.api_code_description, 'Application Conflict', options);
      } else {
        this.toastService.error('Unknown Error', 'Please contact Admin!', options);
      }
      console.error(error);
    });
  }

}
