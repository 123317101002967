import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../_services';
import { first } from 'rxjs/operators';
import { AuthUserDetails } from 'src/app/auth/_models';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { EditUserComponent } from '../edit-user/edit-user.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  id:string;
  modalRef: MDBModalRef;
  userDetails:AuthUserDetails;

  constructor(private userService: UserService, private route: ActivatedRoute, private modalService: MDBModalService, private toastrService: ToastService) {
    this.id = this.route.snapshot.paramMap.get('id');
   }

   ngOnInit() {

    this.getUserDetails();
    
  }

  getUserDetails(): void {
    this.userService.getUser(this.id).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.userDetails = data.data;
            console.log(this.userDetails);
          },
            error => {
                console.error(error);
            });
  }

  createUserFormShow(){
    this.modalRef = this.modalService.show(EditUserComponent, {
      
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-centered',
      containerClass: 'center',
      animated: true,
      scroll:true,
      data: {
          user: this.userDetails
      }
    });
    this.modalRef.content.action.subscribe( (result: any) => { 
      console.log(result); 
      
      if(result == 'close'){
        this.modalRef.hide();
      } else if (result == 'created'){
        this.modalRef.hide();
        const options = { positionClass: 'md-toast-bottom-right' };
        this.toastrService.success('User Changes Successfull', 'Toastr success!', options);
        this.getUserDetails();

      }
    });
  };

}
