import { Component, OnInit } from '@angular/core';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { AuthUserDetailsService } from 'src/app/auth/_services';
import { first } from 'rxjs/operators';
import { PartnerBranch, Role, Status } from 'src/app/auth/_models';
import { Subject } from 'rxjs';
import { NewUser } from '../_models/new-user';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  action: Subject<any> = new Subject();

  partnerId: number;
  showPartner: boolean = false;
  loading: boolean = false;
  contactForm: FormGroup;
  newUser: NewUser;
  partnerOption: any;
  partnerSelect: Array<any>;
  partnerList: Array<PartnerBranch>;
  partnerBranchOption: any;
  partnerBranchSelect: Array<any>;
  partnerBranchList: Array<PartnerBranch>;
  roleOption: any;
  roleSelect: Array<any>;
  roleList: Array<Role>;
  //profileStatusOption: any;
  //profileStatusSelect: Array<any>;
  //profileStatusList: Array<Status>;
  pattern: RegExp = /^[a-zA-Z\']+$/

  constructor(
    private userService: UserService, 
    private authUserDetailsService: AuthUserDetailsService, 
    private fb: FormBuilder, 
    public modalRef: MDBModalRef,
    private toastService: ToastService) {
    this.partnerBranchSelect = [];
    this.partnerSelect = [];
    this.roleSelect = [];
    //this.profileStatusSelect = [];
    this.contactForm = fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(15), Validators.pattern(this.pattern)]],
      lastName: ['', [Validators.required, Validators.maxLength(15), Validators.pattern(this.pattern)]],
      username: ['', [Validators.required, Validators.email]],

      selectPartner: [null],
      selectPartnerBranch: [null, Validators.required],
      selectRole: [null, Validators.required],
      //selectProfileStatus: [null, Validators.required],
  });

   }

  

  ngOnInit() {
    this.loading = true;

    if(this.authUserDetailsService.getPartnerId() == 78){
      this.showPartner = true;
      this.contactForm.get('selectPartner').setValidators([Validators.required]);
      this.contactForm.get('selectPartner').updateValueAndValidity();
      this.userService.getPartnerDetails().pipe(first())
      .subscribe(
        data => {
          this.partnerList = data.data as PartnerBranch[];
          if(this.partnerList.length > 0){
            this.partnerList.forEach(partner =>{
              this.partnerOption = {
                value: partner.id,
                label: partner.name
              }
              this.partnerSelect.push(this.partnerOption);
            })
          }

          this.partnerSelect = this.partnerSelect.slice()
        },
        error => {
            console.error(error);
        })

    } else{
      this.getBranches(this.authUserDetailsService.getPartnerId())
    }

    /*this.userService.getProfileStatus().pipe(first())
    .subscribe(
      data => {
        if(data.status == 200){
          this.profileStatusList = data.body.data;
        if(this.profileStatusList.length > 0){
          this.profileStatusList.forEach(status =>{
              if(status.id == 1){
                //console.log("Its ONE") 
                this.profileStatusOption = {
                  value: status.id,
                  label: status.name
                }
                //console.log(this.profileStatusOption);
                this.profileStatusSelect.push(this.profileStatusOption);
              }
            
          })
        }

        this.profileStatusSelect = this.profileStatusSelect.slice()
        }
      },
      error => {
        console.error(error);
      });*/

        this.userService.getRoles().pipe(first())
        .subscribe(
          data => {
              this.roleList = data.data as Role[];
            if(this.roleList.length > 0){
              this.roleList.forEach(role =>{
                if(this.authUserDetailsService.getPartnerId() != 78){
                  if([5,6].includes(role.id)){
                  this.roleOption = {
                    value: role.id,
                    label: role.role_name,
                    disabled: false
                  }
                  console.log(this.roleOption);
                  this.roleSelect.push(this.roleOption);
                }
                } else{
                  this.roleOption = {
                  value: role.id,
                  label: role.role_name,
                  disabled: false
                }
                console.log(this.roleOption);
                this.roleSelect.push(this.roleOption);
                }
              })
            }
    
            this.roleSelect = this.roleSelect.slice()
          },
            error => {
                console.error(error);
            });
            this.loading = false;
  }
  get firstName() { return this.contactForm.get('firstName'); }
  get lastName() { return this.contactForm.get('lastName'); }
  get username() { return this.contactForm.get('username'); }
  get selectPartner() { return this.contactForm.get('selectPartner'); }
  get selectPartnerBranch() { return this.contactForm.get('selectPartnerBranch'); }
  get selectRole() { return this.contactForm.get('selectRole'); }
  //get selectProfileStatus() { return this.contactForm.get('selectProfileStatus'); }

  closeCreateUserModal(event: String){
    this.action.next(event);
    //this.modalRef.hide();
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      return;
    } 
    this.newUser = {
      first_name: this.contactForm.value.firstName,
      last_name: this.contactForm.value.lastName,
      username: this.contactForm.value.username,
      email_address: this.contactForm.value.username,
      partner_id: this.showPartner ? this.contactForm.value.selectPartner : this.authUserDetailsService.getPartnerId(),
      partner_branch_id: this.contactForm.value.selectPartnerBranch,
      roles: this.contactForm.value.selectRole,
      status: 1
      //status: this.contactForm.value.selectProfileStatus
    };
    console.log(this.newUser);
    this.loading = true;
    this.userService.createUser(this.newUser).pipe(first())
    .subscribe(data => {
      if(data.status == 200 || data.status == 201){
        this.loading = false;
        this.closeCreateUserModal('created')
      }
      //console.log(data.status);
    },
    error => {
      this.loading = false;
      const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-bottom-right'};
      if(error.status == 0){
        this.toastService.error('Network Failure', 'Unable to connect to resource!', options);
      }

      else if(error.status == 401){
        this.toastService.error('Session Failure', 'Invalid Session!', options);
     } else if(error.status == 409){
      this.toastService.error('Account exists with same username!', 'Username Conflict', options);
      } else {
        this.toastService.error('Unknown Error', 'Please contact Admin!', options);
      }
      console.error(error);
    });
  }

  getSelectedPartner(event: any) {
    if(event == null){
      return;
    }
    //console.log(this.selectPartner);
    var partnerValue = this.selectPartner.value;
    if(partnerValue != 78){
      this.roleSelect.find(s => s.value == 1).disabled = true;
      this.roleSelect.find(s => s.value == 2).disabled = true;
    } else{
      this.roleSelect.find(s => s.value == 1).disabled = false;
      this.roleSelect.find(s => s.value == 2).disabled = false;
    }
    this.roleSelect = this.roleSelect.slice()
    //console.log(this.roleSelect);
    this.partnerBranchSelect = [];

    this.getBranches(partnerValue);

  }

  getBranches(patnerId: number){
    this.userService.getPartnerBranchDetails(patnerId).pipe(first())
    .subscribe(
        data => {
            this.partnerBranchList = data.data as PartnerBranch[];
          if(this.partnerBranchList.length > 0){
            this.partnerBranchList.forEach(partnerBranch =>{
              this.partnerBranchOption = {
                value: partnerBranch.id,
                label: partnerBranch.name
              }
              this.partnerBranchSelect.push(this.partnerBranchOption);
            })
          }

          this.partnerBranchSelect = this.partnerBranchSelect.slice()
        },
        error => {
            console.error(error);
        });
  }

}
