import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { McrbApi } from '../_models';

/**
 * @version 1.0
 * @author kevin kanyi
 */
@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private baseUrl: string = environment.baseUrl;

  constructor(private _httpClient: HttpClient) { }

  /**
   * returns an Observable<McrbApi> that contains an array of Partner entity
   * under the data attribute.
   */
  public getPartners(): Observable<McrbApi>{
    return this._httpClient.get<McrbApi>(this.baseUrl + "datasubmission/partner");
  }
}
