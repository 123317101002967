import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadService } from '../upload/_services/upload.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';

function initialsLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.length !== 2) {
    return { 'invalidLength': true };
  }
  return null;
}

function fileNamingFormatLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value as string; // Cast to string assuming the value is a string
  if (value && (value.length < 23 || value.length > 24)) {
    return { 'invalidLength': true };
  }
  return null;
}

function templateNameLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && (control.value.length < 1 || control.value.length > 50)) {
    return { 'invalidLength': true };
  }
  return null;
}

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss']
})
export class UploadTemplateComponent implements OnInit {
  templateForm: FormGroup;


  public templateVersions: Array<any> = [
    { value: 'III', label: 'VERSION III' },
    { value: 'IV', label: 'VERSION IV' }
  ];

  public sectors: Array<any> = [
    { value: '1', label: 'BANK' },
    { value: '2', label: 'MFB' },
    { value: '3', label: 'MFIs' },
    { value: '4', label: 'Non Deposit Taking Saccos' },
    { value: '5', label: 'Regulator' },
    { value: '6', label: 'MCRB' },
    { value: '7', label: 'DFI' },
    { value: '8', label: 'Trade' },
    { value: '9', label: 'Insurance' },
    { value: '10', label: 'Digital Credit Provider' },
    { value: '11', label: 'Deposit Taking Saccos' },
    { value: '12', label: 'Learning Institution' },
    { value: '13', label: 'Self Help Groups' },
    
  ];
  
  public timePeriods: Array<any> = [
    { value: 'M', label: 'MONTHLY' },
    { value: 'D', label: 'DAILY' },
    { value: 'O', label: 'OTHERS' }
  ];
  
  
  constructor(private toastService: ToastService,private _fb: FormBuilder,private _uploadService:UploadService,private _router: Router) { }

  ngOnInit(): void {
    this.templateForm = this._fb.group({
      templateVersion: ['', Validators.required],
      templateName: ['', [Validators.required, templateNameLengthValidator]],
      initials: ['', [Validators.required, initialsLengthValidator]],
      sector: ['', Validators.required],
      timePeriod: ['', Validators.required],
      filenamingformat:['', [Validators.required, fileNamingFormatLengthValidator]],
      file: ['', Validators.required]
    });
  }

onSubmit(): void {
  Object.values(this.templateForm.controls).forEach(control => {
    control.markAsTouched();
  });

  if (this.templateForm.valid) {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;

    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0]; 

      const templateName = this.templateForm.get('templateName').value;
      const sector = this.templateForm.get('sector').value;
      const templateVersion = this.templateForm.get('templateVersion').value;
      const initials = this.templateForm.get('initials').value;
      const fileNamingFormat = this.templateForm.get('filenamingformat').value;
      const timePeriod = this.templateForm.get('timePeriod').value;

      this._uploadService.uploadTemplate(
        file,
        templateName,
        sector,
        templateVersion,
        initials,
        fileNamingFormat,
        timePeriod
      ).subscribe(
        (response: any) => {
          const responseBody = response;
          const timePeriod = responseBody.data.time_period;
          if (timePeriod === 'M') {
            this._router.navigate(['dashboard/monthly']);
          } else if (timePeriod === 'D') {
            this._router.navigate(['dashboard/daily']);
          } 
          else if (timePeriod === 'O') {
            this._router.navigate(['dashboard/others']);
          }else {
            // Redirect to a default page if timePeriod is neither monthly nor daily
            this._router.navigate(['dashboard/default']);
          }
          // Display success message
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Templated Uploaded Successfully",
            showConfirmButton: false,
            timer: 5000
          });
        },
        error => {
          if (error.error && error.error.api_code === 409) {
            Swal.fire({
              icon: "error",
              position: "center",
              timer: 5000,
              text: "Template already exists"
            });
          } else if (error.error && error.error.api_code === 400) {
            Swal.fire({
              icon: "error",
              position: "center",
              title: "Oops...",
              timer: 5000,
              text: "Invalid File Format. Kindly choose .xlsx or .csv file"
            });
          } else {
            Swal.fire({
              icon: "error",
              position: "center",
              title: "Oops...",
              timer: 5000,
              text: "An error occurred while uploading the template. Please try again later."
            });
          }
        }
      );
    } else {
      console.error('No file selected.');
    }
  }
}}