import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { merge, of as observableOf, BehaviorSubject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthUserDetailsService } from '../../auth/_services';
import { first } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UploadService } from '../_services/upload.service';
import { UploadRequestFile, UploadRequest } from '../_models';
import { FileSaverService } from 'ngx-filesaver';
import { PartnerService } from '../../_services/partner.service';
import { Partner } from '../../auth/_models';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-upload',
  templateUrl: './user-upload.component.html',
  styleUrls: ['./user-upload.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class UserUploadComponent implements OnInit, OnDestroy {

  //@ViewChild('ngxLoading', {static: true}) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate', {static: true}) customLoadingTemplate: TemplateRef<any>;
  loadingUploadBatch = false;
  loadingBatchFiles = false;
  downloading = false;
  displayedColumns: string[] = ['no', 'state', 'created'];
  //recDisplayedColumns: string[] = ['fileNo', 'fName', 'fSize', 'progress', 'liveStatus', 'records', 'passed', 'success', 'logs'];
  data: UploadRequest[] = [];
  expandedElement: UploadRequestFile | null;
  //batch requestCode
  requestCode: string;
  hasBatchFile: boolean = true;
  hasBatch: boolean = true;
  requestDataType: string;
  isLoadingResults = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public partnersSelect: any[];
  public form: FormGroup;
  public uploadedBatchFiles: UploadRequestFile[] = [];

  //batchTable values for pagination
  public batchTablePageSize: number = 10;
  public batchTablePageSizeOptions = [10, 20, 30];
  public batchTablePageNo: number = 0;
  public batchTableTotalElements: number = 0;
  private searchParam: string = '';

  private updateFiles: BehaviorSubject<UploadRequestFile[]> = new BehaviorSubject<UploadRequestFile[]>([]);
  private updateFilesObserver = this.updateFiles.asObservable();

  private updateFilesSubscription: Subscription;
  private parterSubscription: Subscription;
  private searchSubscription: Subscription;
  private tableSubscription: Subscription;
  public dataSourceEmpty = false

  public adminBatchFileTableColumns: string[] = ['fileNo', 'fileDetails', 'progress', 'validationStats', 'logs'];
  public batchFileTableColumns: string[] = ['fileNo', 'fileDetails', 'progress', 'validationStats', 'logs'];
  // private currentUserPartnerId: number;

  constructor(private _FileSaverService: FileSaverService, private http: HttpClient, private uploadService: UploadService, private authUserDetailsService: AuthUserDetailsService,
    private _partnerService: PartnerService, private _fb: FormBuilder, private authUserDetails: AuthUserDetailsService) {
    this.form = this._fb.group({
      'search': [null],
      'partner': ['0']
    });
  }

  ngOnInit() {
    //loads for users without role 1 and 3
    // if (!this.isAdmin)
    this.getUploadedBatch();
    //retrieve partner list when user has access to select control
    if (this.isAdmin) {
      this._partnerService.getPartners().subscribe(partners => this.partnerList = partners.data);

      //retrieve users under current users' institution
      // this.currentUserPartnerId = this.authUserDetails.getPartnerId();
      // this.getUploadedBatch(this.currentUserPartnerId);
    }
    //observe changes on partner select
    this.parterSubscription = this.form.get('partner').valueChanges.pipe().subscribe(id => {

      if (this.updateFiles.getValue().length != 0 || this.uploadedBatchFiles.length != 0) {
        this.updateFiles.next([]);
        this.uploadedBatchFiles = [];
      }

      let searchHasValue = (this.form.get('search').value != null);

      if (searchHasValue)
        this.form.get('search').reset();

      //testing
      // (id == undefined || id == '0') ? this.resetBatchData() : this.getUploadedBatch(id)
      (id == undefined || id == '0') ? this.getUploadedBatch() : this.getUploadedBatch(id)

    });
    //observe changes on the search field
    this.searchSubscription = this.form.get('search').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(batchNo => {

      let searchHasValue = (this.form.get('search').value != null);
      if (searchHasValue) {
        this.searchParam = batchNo;
        this.doSearch(batchNo);
      }

    });

    //observe changes on the batch table
    this.tableSubscription = this.paginator.page.asObservable().subscribe(data => {

      this.batchTablePageNo = this.paginator.pageIndex + 1;
      this.batchTablePageSize = this.paginator.pageSize;
      this.uploadedBatchFiles = [];
      this.updateFiles.next([]);
      this.doSearch(this.searchParam)

    });
    //pool from api files with in-progress status
    this.updateFilesSubscription = this.updateFilesObserver.pipe(
      debounceTime(3500),
    ).subscribe(files => {

      if (files.length != 0) {

        let fileIsBeingValidated: boolean =
        this.requestDataType != '004'?(files.find(file => file.status.id == 1 || file.status.id == 3 || file.status.id == 5 || file.status.id == 8) != undefined):
        (files.find(file => file.status.id == 1 || file.status.id == 3 || file.status.id == 4 || file.status.id == 6 || file.status.id == 7 || file.status.id == 11) != undefined)
        ;

        let fileErrorLogIsBeingProcessed: boolean = (files.find(file =>
          (file.status.id == 6 && (file.error_log_status == 1 || file.error_log_status == 2))
        ) != undefined);

        if (fileIsBeingValidated || fileErrorLogIsBeingProcessed) {
          this.uploadService.getBatchFilesUpdates(this.requestCode).subscribe(updates => {
            this.updateFiles.next(updates.data);
            this.uploadedBatchFiles = updates.data;
          });
        }
        else
          this.updateFiles.next([]);
      }
    });
  }

  /**
   * UnSubscribe to all subscriptions
   * to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.updateFilesSubscription.unsubscribe();
    this.parterSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
    this.tableSubscription.unsubscribe();
  }

  /**
   * @description
   * Transforms partner information to an object array that
   * mdb-select can use
   *
   * @param partners array of partners
   */
  private set partnerList(partners: Partner[]) {
    this.partnersSelect = [];
    partners.forEach(partner => this.partnersSelect.push({ value: partner.id, label: partner.name }));
  }

  /**
   * @description
   * Iterates through the list of user Roles
   * and determines if user has a role of MET_ADMIN = 1 or REG_ADMIN = 3
   * and returns true or false where appropriate
   *
   * @returns boolean
   */
  public get isAdmin(): boolean {
    return this.authUserDetails.getRolesList().find(role => role.id == 1 || role.id == 3) != undefined;
  }

  /**
   * @description
   * Retrieves batches from api
   * according to the user role
   *
   * @param partnerId
   * @param batchNo similar to request_code
   */
  private getUploadedBatch(partnerId?: number, batchNo?: string) {
    let userId: string = null;
    let s_partnerId: string = null;
    if ((partnerId == null || partnerId == undefined))// && !this.isAdmin)
      userId = this.authUserDetailsService.getUserId();
    else
      s_partnerId = partnerId.toString();
    this.loadingUploadBatch = true;
    this.uploadService.getUploadBatch(userId, s_partnerId, this.batchTablePageNo == 0 ? 1 : this.batchTablePageNo, this.batchTablePageSize, batchNo)
      .subscribe(response => {
        this.batchTableTotalElements = response.meta != null ? response.meta.total_elements : 0;
        this.data = response.data;

        this.loadingUploadBatch = false;
        this.hasBatch = response.data.length > 0;
        this.batchTablePageNo = this.paginator.pageIndex;

      }, error => {
        this.loadingUploadBatch = false;
        this.resetBatchData();
      })
  }

  /**
   * @description
   * Retrieves updates of uploaded batch files
   * from the api
   *
   * @param updateRequest
   */
  public getBatchUpdates(updateRequest: UploadRequest) {
    //reset batch files
    this.loadingBatchFiles = true;
    this.uploadedBatchFiles = [];
    this.dataSourceEmpty = false;
    this.requestDataType = updateRequest.request_data_type;
    this.uploadService.getBatchFilesUpdates(updateRequest.request_code).subscribe(updates => {
      this.loadingBatchFiles = false;
      this.updateFiles.next(updates.data);
      this.uploadedBatchFiles = updates.data;
      this.requestCode = updateRequest.request_code;
    },
    error => {
      //console.error(error);
      this.loadingBatchFiles = false;
      if(error.status == 404){
        this.dataSourceEmpty = true;
      }

    });
  }
  /**
   * @description
   * Resets batch array
   */
  private resetBatchData() {
    this.data = [];
    this.uploadedBatchFiles = [];
    this.requestDataType = '';
    this.batchTableTotalElements = 0;
    this.batchTablePageNo = this.paginator.pageIndex;
    this.batchTablePageSize = this.paginator.pageSize;
    //get batches for current user
    // this.getUploadedBatch();
  }

  /**
   * Sends a search query to the api
   * for batches with the indicated batch number.
   * it also checks if a partner ha been selected
   * and retireves only the batches for that partner
   * which matches the batch number.
   *
   * @param batchNo
   */
  private doSearch(batchNo: string) {
    //check if user is authorised
    if (this.isAdmin) {
      //check if user has not selected a partner
      if (this.form.get('partner').value == 0 || this.form.get('partner').value == null || this.form.get('partner').value == undefined) {
        if (batchNo != undefined || batchNo != '' || batchNo != null)
          this.getUploadedBatch(null, batchNo);
        else
          this.getUploadedBatch();
      } else {
        //check if batchNo is empty
        if (batchNo == undefined || batchNo == '' || batchNo == null)
          //run getUploadedBatch with partnerId
          this.getUploadedBatch(this.form.get('partner').value);
        else
          this.getUploadedBatch(this.form.get('partner').value, batchNo);
      }
    }
    //do search for unAuthorised user
    else {
      if (batchNo == undefined || batchNo == '' || batchNo == null)
        this.getUploadedBatch();
      else
        this.getUploadedBatch(this.authUserDetailsService.getPartnerId(), batchNo);
    }
  }

  /**
   * @description
   * checks if search has been touched
   */
  public get isSearchTouched() {
    return this.form.controls['search'].touched;
  }

  /**
   * Sends request to start processing error logs
   * to api and updates the batch files
   *
   * @param uploadRequestFile
   */
  public generateBatchFileErrorLogs(uploadRequestFile: UploadRequestFile) {

    this.uploadService.generateBatchFileLogs(uploadRequestFile.reference_code).pipe(
      switchMap(data => this.uploadService.getBatchFilesUpdates(this.requestCode))
    ).subscribe(updates => {

      this.updateFiles.next(updates.data);
      this.uploadedBatchFiles = [...updates.data];
    });
  }
  /**
   * @deprecated use { getUploadedBatch } function instead
   */
  getUploadBatch() {

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.loadingUploadBatch = true;
          return this.uploadService.getUploadRequest(this.authUserDetailsService.getUserId(), this.paginator.pageIndex);
        }),
        map(data => {
          this.loadingUploadBatch = false;
          this.data = data.data;
          this.hasBatch = data.data.length > 0;
          this.batchTableTotalElements = data.meta != null ? data.meta.total_elements : 0;
          this.batchTablePageNo = this.paginator.pageIndex;
          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.loadingUploadBatch = false;

          return observableOf([]);
        })
      ).subscribe(data => {
      });
  }

  /**
   * @deprecated use { getBatchUpdates } function instead
   * @param uploadRequest
   */
  getRecord(uploadRequest: UploadRequest) {
    this.requestDataType = uploadRequest.request_data_type;
    this.uploadedBatchFiles = []
    this.hasBatchFile = true;
    this.requestCode = null;
    this.uploadService.getUploadRequestFiles(uploadRequest.request_code).pipe(first())
      .subscribe(
        data => {
          this.hasBatchFile = true;
          this.loadingBatchFiles = false;
          this.uploadedBatchFiles = data.data;
          this.requestCode = uploadRequest.request_code;
        },
        error => {
          this.loadingBatchFiles = false;
          this.hasBatchFile = false;
        });
  }

  /**
   * @deprecated use { generateBatchFileErrorLogs } function instead
   * @param uploadRequestFile
   */
  generateLogs(uploadRequestFile: UploadRequestFile) {

    this.uploadService.generateLogsRequest(uploadRequestFile.reference_code).pipe(first())
      .subscribe(
        data => {
          this.refreshGetRecord(this.requestCode);
        },
        error => {

        });
  }

  /**
   * @deprecated not in use. Is used by { generateLogs }
   * @param requestCode
   */
  refreshGetRecord(requestCode: string) {
    // this.uploadRequestFiles = [];
    this.uploadedBatchFiles = [];
    this.requestCode = null;
    this.loadingBatchFiles = true;
    this.uploadService.getUploadRequestFiles(requestCode).pipe(first())
      .subscribe(
        data => {
          this.loadingBatchFiles = false;
          this.uploadedBatchFiles = data.data;
          this.requestCode = requestCode;
        },
        error => {
          this.loadingBatchFiles = false;
        });
  }

  downloadErrorLog(downloadUrl: string) {
    this.downloading = true;
    this.uploadService.getErrorLogs(downloadUrl)
      .subscribe(
        data => {

          this._FileSaverService.save(data.body, data.headers.get("filename"));
          this.downloading = false;

        },
        error => { });
  }

  getDisplayedColumns(): string[] {
    //console.log("Accessing this"+this.isAdmin?this.adminBatchFileTableColumns:this.batchFileTableColumns);
    return this.isAdmin?this.adminBatchFileTableColumns:this.batchFileTableColumns;
  }

  downloadFile(file: any){
    console.log(file);
    if(file){
      this.uploadService.downloadFile(file.file_url)
      .subscribe(
        data => {

          this._FileSaverService.save(data.body, data.headers.get("filename"));
          this.downloading = false;

        },
        error => { });
    } else{

    }

  }
}
