import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { AuthenticationService, AuthUserDetailsService } from '../../auth/_services';

import { UUID } from 'angular2-uuid';
import { environment } from '../../../environments/environment';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { fadeInItems } from '@angular/material';

@Component({
  selector: 'app-new-upload',
  templateUrl: './new-upload.component.html',
  styleUrls: ['./new-upload.component.scss']
})
export class NewUploadComponent implements OnInit {
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  file: UploadFile;
  fileCount: number = 0;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  selectedUploadTypeLable: string;
  selectedUploadTypeId: string;
  selectedFinalSubmissionState: string;
  selectedTemplateVersionLable: string;
  selectedTemplateVersionId: string;
  isFinalSelect: Array<any>;
  isFinalSubmissionLable: string;
  isFinalSubmissionId: string;
  uploadFailed: boolean = true;
  baseUrl = environment.baseUrl;
  masterSelected:boolean;
  checked: any;
  checkedList: Array<any>;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  uploadTypeSelect: Array<any>;
  templateVersionSelect: Array<any>;
  finalSubmissionStateSelect: Array<any>;
  closedUsergroupId:number;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private authenticationUserService: AuthUserDetailsService){
    this.masterSelected = false;
    this.checkedList = [];
    this.closedUsergroupId = authenticationUserService.getClosedUserGroup();
    this.uploadTypeSelect = [
      { value: 'M', label: 'Monthly Submission' },
      { value: 'D', label: 'Daily Submission' },
      { value: 'S', label: 'Data Scrub' },

    ];
    console.log("CLOSED USER GROUP ::: "+authenticationUserService.getClosedUserGroup());
    this.templateVersionSelect = [
      { value: '3', label: 'Version III' },
      { value: '4', label: 'Version IV' }
    ];
    this.finalSubmissionStateSelect = [

      //{ value: '', label: 'Select Submission Type', disabled: true },
      { value: 'true', label: 'YES' },
      { value: 'false', label: 'NO' }
    ];
    authenticationUserService.getClosedUserGroup()
    this.options = { concurrency: 1};
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.firstFormGroup = fb.group({
      selectUploadType: [null, Validators.required],
      selectTemplateVersion: [null],
      selectFinalSubmissionState: [null, Validators.required],
  });
  if(authenticationUserService.getClosedUserGroup() == 11 || authenticationUserService.getClosedUserGroup() == 5){
    //console.log(this.templateVersionSelect);
    this.templateVersionSelect.push({value:'5', label:'Insurance Template I'});
    this.templateVersionSelect.slice();
    if(authenticationUserService.getClosedUserGroup() == 11){
      this.templateVersionSelect = this.templateVersionSelect.filter(function( obj ) {
        return obj.value == '5';
      });
    }
    //console.log(this.templateVersionSelect);
  }
  }

  ngOnInit() {
    this.secondFormGroup = new FormGroup({
      password: new FormControl('', Validators.required)
    });
  }

  get password() { return this.secondFormGroup.get('password'); }
  get selectUploadType() { return this.firstFormGroup.get('selectUploadType'); }
  get selectTemplateVersion() { return this.firstFormGroup.get('selectTemplateVersion'); }
  get selectFinalSubmissionState() { return this.firstFormGroup.get('selectFinalSubmissionState'); }
  get f() { return this.firstFormGroup.controls; }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
          // uncomment this if you want to auto upload files when added
          // const event: UploadInput = {
          //   type: 'uploadAll',
          //   url: '/upload',
          //   method: 'POST',
          //   data: { foo: 'bar' }
          // };
          // this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
          this.files.forEach(file => {
            file["upload_type"] = this.selectedUploadTypeLable;
            file["template_version"] = this.selectedTemplateVersionLable;
            file["final_submission_state"] = this.isFinalSubmissionLable;
            file["selected"] = false;
          });
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        if(this.files.length == 0){
          this.files = [];
        }
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // The file is downloaded
        this.files.forEach((file)  => {

          if(file.id == this.files.find(file => file.id === output.file.id).id){
            //console.log("IS:::"+file.id+" =="+this.files.find(file => file.id === output.file.id).id);
            if(file.responseStatus == 0 || file.responseStatus != 200){
              file["upload_success"] = false;
            } else{
              file["upload_success"] = true;
            }
          }

        });
        break;
    }
    this.fileCount = this.files.length;
    console.log(this.fileCount);
  }

  startUpload(): void {
    let currentUser = this.authenticationService.currentUserValue;
    let data = {
      submissionType: this.firstFormGroup.value.selectUploadType,
      templateVersion: this.firstFormGroup.value.selectTemplateVersion,
      finalSubmissionState: this.firstFormGroup.value.selectFinalSubmissionState,
      batchRequestIdentifier: UUID.UUID()
    };

    this.firstFormGroup.value.selectTemplateVersion === null?delete data.templateVersion : undefined;


    const event: UploadInput = {
      type: 'uploadAll',
      url: this.selectedUploadTypeLable != 'Data Scrub'? this.baseUrl+'datasubmission/submit/file':this.baseUrl+'datasubmission/submit/scrub_file',
      headers: {
        Authorization: 'Bearer '+currentUser.access_token,
        Accept: 'application/json'
       },
      data: data,
      method: 'POST'
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }



  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  removeSelectedFiles(): void {
    /*this.checkedList.forEach((checked)  => {
      this.removeFile(this.checked.id);
    });*/
    for (var i = 0; i < this.checkedList.length; i++) {
      this.removeFile(this.checkedList[i].id);
    }
    this.masterSelected = false;
    this.getCheckedItemList();
  }

  getUploadTypeSelectedValue(event: any) {
    if(event == null){
      return;
    }
    this.selectedUploadTypeLable = this.uploadTypeSelect.find(s => s.value == event).label;
    if(this.selectedUploadTypeLable != 'Data Scrub') {
      this.firstFormGroup.get('selectTemplateVersion').setValidators([Validators.required]);
      this.firstFormGroup.get('selectTemplateVersion').setValue(null);
    } else {
      this.firstFormGroup.get('selectTemplateVersion').clearValidators();
      this.firstFormGroup.get('selectTemplateVersion').setValue(null);
    }
    this.firstFormGroup.controls.selectTemplateVersion.updateValueAndValidity();
    //this.selectedUploadTypeId = event;
    //console.log(this.selectedUploadTypeId);
  }

  getTemplateTypeSelectedValue(event: any) {
    if(event == null){
      return;
    }
    this.selectedTemplateVersionLable = this.templateVersionSelect.find(s => s.value == event).label;
    //this.selectedTemplateVersionId = event;
  }

  getSelectedFinalSubmissionState(event: any) {
    if(event == null){
      return;
    }
    //this.selectedFinalSubmissionState = event;
  }

  selectAll() {
    //console.log(this.masterSelected);
    this.files.forEach((file)  => {
      file["selected"] = this.masterSelected;
    });
    this.getCheckedItemList();
    this.hasCheckedFile();
  }

  isAllSelected() {
    this.masterSelected = this.files.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.files.length; i++) {
      if(this.files[i]["selected"])
      this.checkedList.push(this.files[i]);
    }
    //console.log(this.checkedList.length);
  }

  hasCheckedFile(){
    return this.masterSelected || this.checkedList.length > 0 ? false : true;
  }

}
