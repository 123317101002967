import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UserService } from '../_services/user.service';
import { AuthUserDetailsService } from '../../auth/_services';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
//import { NewUserComponent } from '../new-user/new-user.component';
import { ToastService } from 'ng-uikit-pro-standard';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NewUserComponent } from '../new-user/new-user.component';
import { PartnerService } from '../../_services/partner.service';
import { Partner, AuthUserDetails } from '../../../app/auth/_models';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit, OnDestroy {
  modalRef: MDBModalRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  userTableDisplayedColumns: string[] = ['no', 'username', 'branch', 'firstName', 'lastName', 'profileStatus', 'dateCreated', 'view'];
  public dataSource: AuthUserDetails[] = [];
  pageNo: number = 0;
  hasUsers: boolean = false;
  // resultsLength = 0;
  isLoadingResults = false;

  public partnersSelect: any[];
  public form: FormGroup;
  public tempUsers: AuthUserDetails[] = [];

  public userTablePageNo: number = 0;
  public userTablePageSize: number = 10;
  public userTablePageSizeOptions = [10, 20, 30];
  public userTableTotalElements: number = 0;

  private parterServiceSubscription: Subscription;
  private parterSubscription: Subscription;
  private searchSubscription: Subscription;
  private tableSubscription: Subscription;

  private currentUserPartnerId: number;

  constructor(private userService: UserService, private authUserDetailsService: AuthUserDetailsService,
    private modalService: MDBModalService, private toastrService: ToastService, private _partnerService: PartnerService,
    private _fb: FormBuilder, private _authUserDetails: AuthUserDetailsService) {

    this.form = this._fb.group({
      'search': [null],
      'partner': ['0']
    });
  }

  ngOnInit() {
    //retrieves list of partners
    this.parterServiceSubscription = this._partnerService.getPartners().subscribe(partners => this.partnerList = partners.data)
    //observe changes on partner select
    this.parterSubscription = this.form.get('partner').valueChanges.pipe(
    ).subscribe(id => {
      let searchHasValue = (this.form.get('search').value != null);

      if (searchHasValue)
        this.form.get('search').reset();

      this.getUsersByPartnerId(id)
    });
    //observe changes on the search field
    this.searchSubscription = this.form.get('search').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(username => {
      let selectedPartnerId = this.form.get('partner').value;

      if (selectedPartnerId != 0 || selectedPartnerId != '0' || selectedPartnerId != null) {
        if (username == '' || username == null || username == undefined)
          this.getUsersByPartnerId(selectedPartnerId);
        else
          this.search(username)
      }
    });
    //retrieve users under current users' institution
    this.currentUserPartnerId = this._authUserDetails.getPartnerId();
    this.sendRequestForUsersToApi(this.currentUserPartnerId);
    //observe changes on the batch table
    this.tableSubscription = this.paginator.page.asObservable().subscribe(data => {
      let searchHasValue = (this.form.get('search').value != null);

      if (searchHasValue)
        this.form.get('search').reset();

      let selectedPartnerId: number = this.form.get('partner').value;
      this.userTablePageNo = this.paginator.pageIndex == 0 ? 1 : this.paginator.pageIndex;
      this.userTablePageSize = this.paginator.pageSize;

      if (selectedPartnerId == 0 || selectedPartnerId == undefined || selectedPartnerId == null) 
        this.getUsersByPartnerId(this.currentUserPartnerId);
      else 
        this.getUsersByPartnerId(selectedPartnerId);

    });
  };

  /**
  * UnSubscribe to all subscriptions
  * to prevent memory leaks
  */
  ngOnDestroy(): void {
    this.parterServiceSubscription.unsubscribe();
    this.parterSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
    this.tableSubscription.unsubscribe();
  }

  /**
   * retrieves users for a particular partner 
   * on triggering the on change select event
   * @param partnerId
   */
  public getUsersByPartnerId(partnerId: number) {
    this.dataSource = [];
    this.tempUsers = [];
    partnerId == null || partnerId == undefined || partnerId == 0 ? this.resetUserData() : this.sendRequestForUsersToApi(partnerId);
  }

  /**
   * sends request for users of a particular partner
   * to the api
   * @param partnerId of type number
   */
  private sendRequestForUsersToApi(partnerId: number) {
    this.isLoadingResults = true;
    //confirm paginator params are set
    let noPagnationParams = (this.paginator.pageIndex == null || this.paginator.pageIndex == undefined || this.paginator.pageSize == null || this.paginator.pageSize == undefined);
    if (noPagnationParams) {
      this.paginator.pageIndex = this.pageNo;
      this.paginator.pageSize = this.userTablePageSize;
    }
    this.userService.getUsersByPartnerId(partnerId, this.paginator.pageIndex, this.paginator.pageSize).subscribe(data => {
      this.isLoadingResults = false;
      this.dataSource = data.data;
      this.hasUsers = data.data.length > 0;
      this.setUserData(data.data);
      this.userTableTotalElements = data.meta != null ? data.meta.total_elements : 0;
    }, error => {
      this.isLoadingResults = false;
      this.resetUserData();
    });
  }

  /**
   * transforms partner information to an object array that 
   * mdb-select can use
   * @param partners type Partner[]
   */
  private set partnerList(partners: Partner[]) {
    this.partnersSelect = [];
    partners.forEach(partner => this.partnersSelect.push({ value: partner.id, label: partner.name }));
  }

  /**
   * sets the array of users to be displayed 
   * and also instantiates a cache for search
   * purposes
   * @param users
   */
  private setUserData(users: AuthUserDetails[]) {
    this.dataSource = users;
    // cache users
    this.tempUsers = [...users];
    this.hasUsers = users.length > 0;
    // this.pageNo = this.paginator.pageIndex;
  }

  /**
   * resets all user data(array) selected previously 
   * for a particular partner
   */
  private resetUserData() {
    this.dataSource = [];
    this.hasUsers = false;
    // this.resultsLength = 0;
    this.userTableTotalElements = 0;
    this.userTablePageNo = this.paginator.pageIndex;
    this.userTablePageSize = this.paginator.pageSize;
    // this.pageNo = this.paginator.pageIndex;
  }

  /**
   * searches by username in the cached user data
   * @param username of type string
   */
  private search(username: string) {
    const searchParam = username.toLowerCase().trim();
    // filter our data
    let temp = this.tempUsers.filter(user => {
      return user.username.toLowerCase().indexOf(searchParam) !== -1 || !searchParam;
    });
    // update the rows
    this.dataSource = temp;
    // Whenever the filter changes, always go back to the first page
    this.paginator.length = temp.length;
  }

  createUserFormShow() {
    this.modalRef = this.modalService.show(NewUserComponent, {

      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-centered',
      containerClass: 'center',
      animated: true,
      scroll: true,
    });
    this.modalRef.content.action.subscribe((result: any) => {

      if (result == 'close') {
        this.modalRef.hide();
      } else if (result == 'created') {
        this.modalRef.hide();
        const options = { positionClass: 'md-toast-bottom-right' };
        this.toastrService.success('User Creation Successfull', 'Toastr success!', options);
        this.getUsers();

      }
    });
  };

  /**
   * @deprecated use { getUsersByPartnerId } function instead
   */
  getUsers() {

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.userService.getUsers(this.authUserDetailsService.getPartnerId(), this.paginator.pageIndex == 0 ? 1 : this.paginator.pageIndex);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.dataSource = data.data;
          this.hasUsers = data.data.length > 0;

          // this.resultsLength = data.meta != null ? data.meta.total_elements : 0;
          this.userTableTotalElements = data.meta != null ? data.meta.total_elements : 0;
          // this.pageNo = this.paginator.pageIndex;

          return data.data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.isLoadingResults = false;

          return observableOf([]);
        })
      ).subscribe(data => {
        //this.data = data;
        //this.requestDataType = data.length > 0 ? data[0].request_data_type: '';
      });
  }

}