import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CurrentUser , McrbApi} from '../../_models';
import { Token } from '../_models/token';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Token>;
    public currentUser: Observable<Token>;
    grant_type:string = "password";
    //private_key = environment.privateKey;
    //public_key = environment.publicKey;
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Token {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        //console.log("Calling Service NOW.....");
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
              //'Authorization': 'Basic '+btoa(this.public_key+":"+this.private_key),
              //"Content-type": "application/x-www-form-urlencoded; charset=utf-8"
            })
          };
        const body = {
            username: username,
            password: password
        }

        
        //return this.http.post<any>(this.baseUrl+'datasubmission/authenticate?grant_type=password&password='+encodeURIComponent(password)+'&username='+username, null, httpOptions)
        return this.http.post<any>(this.baseUrl+"datasubmission/login", body, httpOptions)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }))
    }

    /*postResetRequest(username: String){
      return this.http.post<McrbApi>(this.baseUrl+'datasubmission/forgotPassword?='+username, null)
    }*/

    postResetRequest(username: String){
        const href = this.baseUrl+'datasubmission/forgotPassword';
        const requestUrl = `${href}?email=${username}`;
        return this.http.post<McrbApi>(requestUrl,null);
    }

    validateToken(token: String){
        const href = this.baseUrl+'datasubmission/confirmResetToken';
        const requestUrl = `${href}?token=${token}`;
        return this.http.post<McrbApi>(requestUrl,null);
    }

    resetPassword(body: String){
        const href = this.baseUrl+'datasubmission/resetPassword';
        const requestUrl = `${href}`;
        const httpOptions: {headers;} = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Accept': 'application/json'
            })
          };
        return this.http.post<McrbApi>(requestUrl,body,httpOptions);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}