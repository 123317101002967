import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services';
import { first } from 'rxjs/operators';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { Data } from 'src/app/_models/data';

@Component({
  selector: 'app-reset-request',
  templateUrl: './reset-request.component.html',
  styleUrls: ['./reset-request.component.scss']
})
export class ResetRequestComponent implements OnInit {

  resetRequestForm: FormGroup;
  returnUrl: string;
  loading = false;
  //navigationExtra: NavigationExtras;

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private toastService: ToastService, private data: Data) {
    this.resetRequestForm = formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
  });
   }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/reset_request_success';
  }

  get username() { return this.resetRequestForm.get('username'); }

  onSubmit() {
    if (this.resetRequestForm.invalid) {
      return;
    }
    this.loading = true;

    this.authenticationService.postResetRequest(this.resetRequestForm.value.username)
    .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          /*this.navigationExtra = {
            queryParams: {['data']: data.data},
            preserveQueryParams:false
            
          }*/
          this.data.storage = data.data;
          this.router.navigate([this.returnUrl]);
        
        },
        error => {
          this.loading = false;
          const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-top-center'};
          if(error.status == 0){
            this.toastService.error('Network Failure', 'Unable to connect to resource!', options);
          }
    
          else if(error.status == 401){
            this.toastService.error('Session Failure', 'Invalid Session!', options);
         } else if(error.status == 404){
          this.toastService.error('Email Account details not found. Please verify email!', 'Email not found', options);
          } else {
            this.toastService.error('Unknown Error', 'Please contact Admin!', options);
          }
          console.error(error);
        }
      );
  }    

}
