import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { McrbApi } from 'src/app/_models';
import { NewUser } from '../_models/new-user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.baseUrl;
  mcrbApi: McrbApi;

  constructor(private http: HttpClient) { }

  /**
   * Sends request to api to retrieve all users
   * of a particular partner
   * @param partnerId 
   * @param pageNo 
   * @param pageSize 
   */
  public getUsersByPartnerId(partnerId: number, pageNo: number, pageSize: number){
    let params = new HttpParams()
      .set('partner_id', partnerId.toString())
      .set('page', pageNo.toString())
      .set('page_size', pageSize.toString());
    return this.http.get<McrbApi>(this.baseUrl + 'datasubmission/users', { params });
  }

  getUser(userId: string) {
    const href = this.baseUrl + 'datasubmission/user';
    const requestUrl = `${href}?id=${userId}`;
    //return this.http.get<McrbApi>(requestUrl, { observe: 'response' });
    return this.http.get<McrbApi>(requestUrl);
  }

  getUsers(partnerId: number, page: number) {
    const href = this.baseUrl + 'datasubmission/users';
    const requestUrl = `${href}?partner_id=${partnerId}&page=${page}`;
    //return this.http.get<McrbApi>(requestUrl, { observe: 'response' });
    return this.http.get<McrbApi>(requestUrl);
  }

  getPartnerDetails() {
    const href = this.baseUrl + 'datasubmission/partner';
    const requestUrl = `${href}`;
    return this.http.get<McrbApi>(requestUrl);
  }

  getPartnerBranchDetails(partnerId: number) {
    const href = this.baseUrl + 'datasubmission/partner/branch';
    const requestUrl = `${href}?partner_id=${partnerId}`;
    return this.http.get<McrbApi>(requestUrl);
  }

  getRoles() {
    const href = this.baseUrl + 'datasubmission/role';
    const requestUrl = `${href}`;
    return this.http.get<McrbApi>(requestUrl);
  }

  getProfileStatus() {
    const href = this.baseUrl + 'datasubmission/profilestatus';
    const requestUrl = `${href}`;
    return this.http.get<McrbApi>(requestUrl);
  }

  getSessionTime() {
    const href = this.baseUrl + 'datasubmission/sessiontimeout';
    const requestUrl = `${href}`;
    return this.http.get<McrbApi>(requestUrl);
  }

  createUser(newUser: NewUser): Observable<any> {
    let body = JSON.stringify(newUser);
    const httpOptions: { headers; observe; } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      observe: 'response'
    };
    return this.http.post(this.baseUrl + 'datasubmission/user', body, httpOptions);
  }

  createApp(newApp: String) {
    let body = newApp;
    const httpOptions: { headers; } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };
    return this.http.post<McrbApi>(this.baseUrl + 'datasubmission/createApp', body, httpOptions);
  }

  updateUser(updateUser: NewUser): Observable<any> {
    let body = JSON.stringify(updateUser);
    const httpOptions: { headers; observe; } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      observe: 'response'
    };
    return this.http.put(this.baseUrl + 'datasubmission/user', body, httpOptions);
  }
}