import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { first } from 'rxjs/operators';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from 'src/app/_util/confirm-password-validator';
import { Data } from 'src/app/_models/data';

@Component({
  selector: 'app-reset-request-activate',
  templateUrl: './reset-request-activate.component.html',
  styleUrls: ['./reset-request-activate.component.scss']
})
export class ResetRequestActivateComponent implements OnInit {
  token: string;
  showInvalidTokenView: boolean = false;
  showPasswordResetView: boolean = false;
  showSuccessResetView: boolean = false;
  resetRequestActivateForm: FormGroup;
  resetDetails: any;
  firstName: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService,
    private mdbSpinningPreloader: MDBSpinningPreloader, private data: Data) {
      this.resetRequestActivateForm = formBuilder.group({
        npassword: ['', [Validators.required, Validators.pattern(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*?._()^]).{6,20})/)]],
        confirmPassword: ['']
    },{
      validator: ConfirmPasswordValidator.MatchPassword
    });
    this.route.queryParams.subscribe(params => {
      this.token = params["token"];
    });

    window.history.pushState({}, document.title, "reset_request_activate" + "");
    if(this.token == null){
      this.router.navigate(['/'])
    }
    mdbSpinningPreloader.start;
   }

  ngOnInit() {

    this.authenticationService.validateToken(this.token).pipe(first())
    .subscribe(
      data => {
        this.mdbSpinningPreloader.stop
        console.log(data);
        this.showPasswordResetView = true;
        console.log(this.resetRequestActivateForm);
        //this.loading = false;
        /*this.navigationExtra = {
          queryParams: {['data']: data.data},
          preserveQueryParams:false
          
        }*/
        //this.data.storage = data.data;
        //this.router.navigate([this.returnUrl]);
      
      },
      error => {
        this.mdbSpinningPreloader.stop
        //this.loading = false;
        const options = { preventDuplicates: true, enableHtml: false,  positionClass: 'md-toast-top-center'};
        if(error.status == 0){
          //this.toastService.error('Network Failure', 'Unable to connect to resource!', options);
        }
  
        else if(error.status == 401){
          //this.toastService.error('Session Failure', 'Invalid Session!', options);
       } else if(error.status == 404){
         this.showInvalidTokenView = true;
        //this.toastService.error('Email Account details not found. Please verify email!', 'Email not found', options);
        } else {
          //this.toastService.error('Unknown Error', 'Please contact Admin!', options);
        }
        console.error(error);
      }
    );

  }

  get npassword() { return this.resetRequestActivateForm.get('npassword'); }
  get confirmPassword() { return this.resetRequestActivateForm.get('confirmPassword'); }
  get f() { return this.resetRequestActivateForm.controls; }

  onSubmit() {
    if (this.resetRequestActivateForm.invalid) {
      return;
    }
    console.log(this.f);
    this.resetDetails = {
      //confirmPassword: this.f.confirmPassword.value,
      password: this.f.npassword.value,
      token: this.token
    }
    this.authenticationService.resetPassword(JSON.stringify(this.resetDetails)).pipe(first())
    .subscribe(
      data => {
        console.log(data);
        this.data.storage = data.data;
        this.showPasswordResetView = false;
        this.showSuccessResetView= true;
        this.firstName = this.data.storage.first_name;
      },
      error => {
        console.error(error);
      }
    );
  }

}
