import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadService } from '../upload/_services/upload.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Role } from '../auth/_models';
import { AuthUserDetailsService } from '../auth/_services';
import { ToastService } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';

function initialsLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.length !== 2) {
    return { 'invalidLength': true };
  }
  return null;
}
function templateNameLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && (control.value.length < 1 || control.value.length > 50)) {
    return { 'invalidLength': true };
  }
  return null;
}

function fileNamingFormatLengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value as string; // Cast to string assuming the value is a string
  if (value && (value.length < 23 || value.length > 24)) {
    return { 'invalidLength': true };
  }
  return null;
}

@Component({
  selector: 'app-monthly-template',
  templateUrl: './monthly-template.component.html',
  styleUrls: ['./monthly-template.component.scss']
})
export class MonthlyTemplateComponent implements OnInit {
  public readonly PAGE_SIZE: number = 20;
  public readonly MAX_SIZE: number = 20;
  public totalItems: number = 0;
  public PAGE: number = 1;

  roles: Array<Role> = [];
  roleIds: Array<number> = [];
  displayEditDelete: boolean = false;
  cugId:number;
  templateForm: FormGroup;


  public templateVersionSelect: any[] = [];

  @ViewChild("editTemplateModal", { static: true })
  public editTemplateModal: any;


  public allTemplates: any[] = [];

  private currentReferenceCode: string;
  searchValue:any = {value: '', label: ''};
  searchCriteria: any = { value: '', label: '' };


  public isLoading = false;

  public templateVersions: Array<any> = [
    { value: 'III', label: 'VERSION III' },
    { value: 'IV', label: 'VERSION IV' }
  ];

  public sectors: Array<any> = [
    { value: 1, label: 'BANK' },
    { value: 2, label: 'MFB' },
    { value: 3, label: 'MFIs' },
    { value: 4, label: 'Non Deposit Taking Saccos' },
    { value: 5, label: 'Regulator' },
    { value: 6, label: 'MCRB' },
    { value: 7, label: 'DFI' },
    { value: 8, label: 'Trade' },
    { value: 9, label: 'Insurance' },
    { value: 10, label:'Digital Credit Provider' },
    { value: 11, label: 'Deposit Taking Saccos' },
    { value: 12, label: 'Learning Institution' },
    { value: 13, label: 'Self Help Groups' },
    
  ];
  
  public timePeriods: Array<any> = [
    { value: 'M', label: 'MONTHLY' },
    { value: 'D', label: 'DAILY' }
  ];


  constructor(private toastService: ToastService,private _fb: FormBuilder,private authUserDetails: AuthUserDetailsService,private http: HttpClient,private _uploadsService:UploadService,private _router: Router) { 

    this.templateForm = this._fb.group({
      templateVersion: ['', Validators.required],
      templateName: ['', [Validators.required, templateNameLengthValidator]],
      initials: ['', [Validators.required, initialsLengthValidator]],
      sector: ['', Validators.required],
      filenamingformat:['', [Validators.required, fileNamingFormatLengthValidator]],
    });
  }
  onTemplateversion(event: any) {
    // Update the search criteria
    this.searchCriteria = event;
    this.searchValue = event;
  
    // Clear previous data
    this.allTemplates = [];
  
    // Fetch new data
    this._uploadsService.getListOfTemplatesPerTemplateVersion('M', false, this.searchCriteria.value,this.PAGE_SIZE.toString()).pipe(
      tap(value => this.isLoading = true)
    ).subscribe(response => {
      this.allTemplates = response.data;
      this.totalItems = response.meta.total_elements;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.allTemplates = [];
    });
  }

  ngOnInit() {
    //this.paginateTemplates();
    this.roles = this.authUserDetails.getRolesList();
    this.roles.forEach(role =>{
      this.roleIds.push(role.id);
    });
    this.cugId=this.authUserDetails.getCugId() != null ? this.authUserDetails.getCugId() : 0;

    this.displayEditDelete=this.roleIds.includes(1)

    if (this.displayEditDelete === true) {
      this.getListOfAllMonthlyTemplates();
    } else {
      this.getListOfAllMonthlyTemplatesPerSector();
    }
  }


  cancelButton(){
    this.editTemplateModal.hide();
    this.templateForm.reset();
  }

  downloadFile(reference_code: string, fileName: string): void {
    this._uploadsService.downloadFiles(reference_code, fileName);
  }
  showEditTemplateModal(referenceCodeId: string) {
    // Find the template with the given reference code
    this.currentReferenceCode=referenceCodeId;
    const templateToEdit = this.allTemplates.find(template => template.reference_code === referenceCodeId);
    console.log(templateToEdit)
    
    // Populate the form fields with the data
    this.templateForm.patchValue({
      templateVersion: templateToEdit.template_version,
      templateName: templateToEdit.template_name,
      initials: templateToEdit.file_type,
      sector: templateToEdit.sector,
      filenamingformat: templateToEdit.file_naming_format
    });
  
    // Open the editTemplateModal
    this.editTemplateModal.show();
  }
  
  public editNow(): void {
   
    
    const template_version = this.templateForm.get('templateVersion').value;
    const template_name = this.templateForm.get('templateName').value;
    const initials = this.templateForm.get('initials').value;
    const sector = this.templateForm.get('sector').value;
    const file_naming_format = this.templateForm.get('filenamingformat').value;

    Object.values(this.templateForm.controls).forEach(control => {
      control.markAsTouched();
    });
  
    // Check if the form is valid
    if (this.templateForm.valid) {
      // Call the service to update the promoter
      this._uploadsService.updateTemplateInfo(
        template_version,
        template_name,
        initials,
        sector,
        file_naming_format,
        this.currentReferenceCode,
      ).subscribe({
        next: (data) => {
         // this._toastr.success('Promoter Updated Successfully', 'Success');
          this.templateForm.reset();
          this.editTemplateModal.hide();
          this.getListOfAllMonthlyTemplates();
        },
        error: (error) => {
          //this._toastr.error(error); // Display the specific error message
          console.log(error);
        },
      });
    }
  }
 
  deleteTemplate(reference_code: string) {
    // Display the confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms, proceed with the deletion
        this._uploadsService.deleteTemplate(reference_code).subscribe(
          response => {
            // Display success message
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Template deleted successfully",
              showConfirmButton: false,
              timer: 3000
            });
            // Refresh the list of templates and navigate to the appropriate route
          this.getListOfAllMonthlyTemplates();
          this._router.navigate(['dashboard/monthly']);
          },
          error => {
            Swal.fire({
              icon: "error",
              position:"center",
              title: "Oops...",
              timer: 5000,
              text: "Failed to Delete the Template!"
            });
          }
        );
      }
    });
  }
  

    getListOfAllMonthlyTemplates(){
      this._uploadsService.getListOfTemplates('M',this.PAGE_SIZE.toString(),false).pipe(
        tap(value => this.isLoading = true)
      ).subscribe(response => {
        this.allTemplates = response.data;
  
        console.log(this.allTemplates);
        this.totalItems = response.meta.total_elements;
        this.isLoading = false;
      }, error => this.isLoading = false);
    }

 

    getListOfAllMonthlyTemplatesPerSector(){
    
    
      this._uploadsService.getListOfPerSector('M',this.PAGE_SIZE.toString(),false,this.cugId).pipe(
        tap(value => this.isLoading = true)
      ).subscribe(response => {
        this.allTemplates = response.data;
  
        console.log(this.allTemplates);
        this.totalItems = response.meta.total_elements;
        this.isLoading = false;
      }, error => this.isLoading = false);
    }
    pageChanged(event: any): void {
      this.PAGE = event.page;
      this.getListOfAllMonthlyTemplatesPerSector(); // Call the method to fetch data for the new page
    }

  
  
  
  }
  

  


