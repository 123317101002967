import { Component, OnInit } from '@angular/core';
import { Data } from 'src/app/_models/data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-request-success',
  templateUrl: './reset-request-success.component.html',
  styleUrls: ['./reset-request-success.component.scss']
})
export class ResetRequestSuccessComponent implements OnInit {
  firstName: string;
  username: string;

  constructor(private data: Data, private router: Router) {
    if(this.data.storage == null){
      this.router.navigate(['/'])
      //console.log("Its null");
    }else{
      //console.log("has data");
      //console.log(JSON.stringify(this.data.storage));
      this.firstName = this.data.storage.first_name;
      this.username = this.data.storage.username;
    }
  }

  ngOnInit() {

  }

}
