import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { SidenavComponent} from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../auth/_services';
import { AuthUserDetailsService } from '../auth/_services';
import { Role } from '../auth/_models';
import { UserIdleService } from 'angular-user-idle';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { SessionTimeoutComponent } from '../auth/session-timeout/session-timeout.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild("sidenav", {static: true}) sidenav: SidenavComponent;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  sidenavBreakpoint;
  public innerWidth: any;
  partnerName: string;
  cugId:number;
  branchName: string;
  institutionCode:string;
  names: string;
  roles: Array<Role> = [];
  roleIds: Array<number> = [];
  displayUserMgtView: boolean = false;
  displayUploadTemplate:boolean=false;
  displayClientCreationView: boolean = false;
  modalRef: MDBModalRef;
  action: Subject<any> = new Subject();

  constructor(private modalService: MDBModalService, private userIdle: UserIdleService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private renderer: Renderer2, private hostElement: ElementRef,
    private authenticationService: AuthenticationService,
    private authUserDetails: AuthUserDetailsService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(
      count => {
         if(count == 1){
          this.showSessionTimeoutView();
         }
         //console.log(count);
      });
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => 
    {
      console.log('Time is up!')
      //this.logout();
    });


    this.innerWidth = window.innerWidth;
    //console.log(this.innerWidth);
    //console.log(this.userService.getUserDetails());
    //console.info(this.userService.getPartnerName());
    
    this.roles = this.authUserDetails.getRolesList();
    this.roles.forEach(role =>{
      this.roleIds.push(role.id);
    });
    //console.log("Roles Available::"+this.roleIds);
    this.displayUserMgtView = this.roleIds.includes(1) || this.roleIds.includes(5) || this.roleIds.includes(3);
    this.displayUploadTemplate=this.roleIds.includes(1)
    this.displayClientCreationView = this.roleIds.includes(7);
    //console.log(this.displayUserMgtView);
    //this.displayUserMgtView = this.roles.find((role) => role.id == 1) != null;
    
    this.partnerName = this.authUserDetails.getPartnerName() != null ? this.authUserDetails.getPartnerName() : "{Not Avalibale::}";
    this.branchName = this.authUserDetails.getBranchName() != null ? this.authUserDetails.getBranchName() : "{Not Avalibale::}";
    this.institutionCode = this.authUserDetails.getInstitutionCode() != null ? this.authUserDetails.getInstitutionCode() : "{Not Avalibale::}";
    this.names =  this.authUserDetails.getUserNames() != null ? this.authUserDetails.getUserNames() : "-";

    this.cugId=this.authUserDetails.getCugId() != null ? this.authUserDetails.getCugId() : 0;

  
  }

  ngAfterViewInit() {
    console.log(this.mobileQuery);
    //this.sidenav.show();
    if (!this.mobileQuery.matches) {
      //this.renderer.setStyle(document.getElementById('mainc'), "margin-left", "240px");
      if(this.innerWidth >= 1280 && this.innerWidth <= 1366){
        this.renderer.setStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left", "15rem");
        //this.renderer.setStyle(document.getElementById('mainc'), "padding-left", "15rem");
      } else{
        this.renderer.removeStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left");
      }
      /*this.renderer.setStyle(this.hostElement.nativeElement.querySelector('nav'), "margin-left", "240px");
      this.renderer.setStyle(document.getElementById('btnNav'), "display", "none");*/
      //this.renderer.setStyle(document.getElementById('sidenav-overlay'), "display", "none");
    } else {
      this.renderer.removeStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left");
      //this.renderer.setStyle(document.getElementById('mainc'), "margin-left", "0");
      /*this.renderer.setStyle(this.hostElement.nativeElement.querySelector('nav'), "margin-left", "0");
      this.renderer.setStyle(document.getElementById('btnNav'), "display", "block");*/
      //this.renderer.setStyle(document.getElementById('sidenav-overlay'), "display", "block");
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log("Resizing!!!!!!!!!!!!!!!!!");
    //this.sidenav.show();
    //console.log(this.mobileQuery.matches);
    if (!this.mobileQuery.matches) {
      //this.renderer.setStyle(document.getElementById('mainc'), "margin-left", "240px");
      //console.log(this.innerWidth);
      if(event.target.innerWidth >= 1280 && event.target.innerWidth <= 1366){
        //console.log("Resizing");
        //this.renderer.removeStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left");
        //this.renderer.removeStyle(document.getElementById('mainc'), "padding-left");
        this.renderer.setStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left", "15rem");
        //this.renderer.setStyle(document.getElementById('mainc'), "padding-left", "15rem");
      }else{
        this.renderer.removeStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left");
        //this.renderer.removeStyle(document.getElementById('mainc'), "padding-left");
      }
      /*this.renderer.setStyle(this.hostElement.nativeElement.querySelector('nav'), "margin-left", "240px");
      this.renderer.setStyle(document.getElementById('btnNav'), "display", "none");*/
      //this.renderer.setStyle(document.getElementById('sidenav-overlay'), "display", "none");
      

    } else {
      this.renderer.removeStyle(this.hostElement.nativeElement.querySelector('nav'), "padding-left");
      //this.renderer.removeStyle(document.getElementById('mainc'), "padding-left");
      
      //this.renderer.setStyle(document.getElementById('mainc'), "margin-left", "0");
      /*this.renderer.setStyle(this.hostElement.nativeElement.querySelector('nav'), "margin-left", "0");
      this.renderer.setStyle(document.getElementById('btnNav'), "display", "block");*/
      //this.renderer.setStyle(document.getElementById('sidenav-overlay'), "display", "block");
    }
  }

  logout() { 
    this.authenticationService.logout();
    location.reload();
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  showSessionTimeoutView(){
    this.modalRef = this.modalService.show(SessionTimeoutComponent, {
      
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-centered modal-notify modal-warning',
      containerClass: 'center',
      animated: true,
      scroll:true,
      data: {
          first_name: this.authUserDetails.getFirstName()
      }
    });
    this.modalRef.content.action.subscribe( (result: any) => { 
      console.log(result); 
      this.modalRef.hide();
      if(result == 'close'){
        this.logout();
      } else if (result == 'continue'){
        this.restart();
      }
    });
  }

}
